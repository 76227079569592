<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-row>
        <a-col :span="22">
          <a-form-model layout="inline" @keyup.enter.native="query">
            <a-form-model-item>
              <a-input v-model="form.code" placeholder="编码" />
            </a-form-model-item>
            <a-form-model-item>
              <a-input v-model="form.name" placeholder="名称" />
            </a-form-model-item>
            <a-form-model-item>
              <a-button-group>
                <a-tooltip title="查询">
                  <a-button @click="query" type="primary">查询</a-button>
                </a-tooltip>
                <a-tooltip title="刷新">
                  <a-button @click="reset">重置</a-button>
                </a-tooltip>
              </a-button-group>
            </a-form-model-item>
          </a-form-model>
        </a-col>
        <a-col :span="2">
          <div class="right" style="margin-top: 4px">
            <a-button
              v-if="$getPermission(`${$route.path}/add`)"
              @click="$router.push(`${$route.path}/add`)"
              type="primary"
              >新建</a-button
            >
          </div>
        </a-col>
      </a-row>

      <Padding />

      <a-table
        bordered
        :dataSource="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
      >
        <a-table-column title="编码" data-index="code"></a-table-column>
        <a-table-column title="名称" data-index="name"></a-table-column>
        <a-table-column title="描述" data-index="remarks"></a-table-column>
        <a-table-column
          title="创建者"
          data-index="createUserName"
          align="center"
        ></a-table-column>
        <a-table-column
          title="创建时间"
          data-index="createAt"
          align="center"
        ></a-table-column>
        <a-table-column title="操作" align="right">
          <template slot-scope="text">
            <a-space>
              <a
                href="#"
                v-if="$getPermission(`${$route.path}/edit`)"
                @click.prevent="
                  $router.push(`${$route.path}/edit?uuid=${text.uuid}`)
                "
                >编辑</a
              >
              <a
                href="#"
                v-if="$getPermission(`${$route.path}/delete`)"
                class="danger"
                @click.prevent="deleteText(text)"
                >删除</a
              >
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import { fetchList, remove } from "@/api/setting/module";

export default {
  data() {
    return {
      form: {},

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      const { current, pageSize } = this;
      this.loading = true;
      fetchList({
        pageNum: current,
        pageSize,
        ...this.form,
      })
        .then((res) => {
          if (Array.isArray(res.list) && typeof res.totalSize === "number") {
            this.list = res.list;
            this.total = res.totalSize;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    deleteText(text) {
      const that = this;
      this.$confirm({
        title: "确认要删除这个模块吗？",
        onOk() {
          remove({
            uuid: text.uuid,
          }).then(() => {
            that.getList();
          });
        },
      });
    },

    query() {
      this.current = 1;
      this.pageSize = 10;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.pageSize = 10;
      this.getList();
    },
  },
};
</script>