import request from '../request'

export function fetchList(params) {
    return request({
        url: '/user-service/module/list',
        params
    })
}

export function add(data) {
    return request({
        url: '/user-service/module/add',
        method: 'POST',
        data,
    })
}

export function fetchDetail(params) {
    return request({
        url: '/user-service/module/query',
        params
    })
}

export function edit(data) {
    return request({
        url: '/user-service/module/update',
        method: 'POST',
        data,
    })
}

export function remove(data) {
    return request({
        url: '/user-service/module/del',
        method: 'POST',
        data
    })
}